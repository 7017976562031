import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import ButtonGroup from '@mui/material/ButtonGroup';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import EmailIcon from '@mui/icons-material/Email';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import validator from 'validator'

import { API, Auth } from 'aws-amplify';

import Nav from '../shared/Nav';
import { useAppContext } from '../lib/contextLib';
import CreateAlert from '../shared/CreateAlert';

export default function TeamInvites() {

  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  
  const [invites, setInvites] = useState([]);
  const [isJoinDisabled, setisJoinDisabled] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { currentTeam } = useAppContext();

  useEffect(() => {
    async function getInfo() {
      try {
        setIsLoading(true);
        let teamInvites = await API.get('api', '/team/invites');
        console.log(JSON.stringify(teamInvites));
        setInvites(teamInvites);
        setIsLoading(false);
      } catch (e) {
        console.error('Error getting the alerts', e);
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    }
    console.log('should refresh 2', shouldRefresh);
    if (shouldRefresh) {
      getInfo();
    }
  }, [currentTeam, shouldRefresh]);

  async function acceptInvite(invite) {
    try {
      setisJoinDisabled(true);
      await API.post('api', '/team/invites/accept', {
        body: {
          teamId: invite.teamId
        }
      });
      setShouldRefresh(true);
    } catch (e) {
      console.error('Error accepting the invite to join the team', e);
      setHasError(true);
    } finally {
      setisJoinDisabled(false);
      setTimeout(() => {
        setHasError(false);
      }, 3000);
    }
  }

  

  function renderBody() {
    if (isLoading) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
          <CircularProgress />
        </div>
      )
    }
    if (invites.length === 0) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
          <Typography variant="h5">No Team Invites</Typography>
        </div>
      )
    }
    return (
      <>
        <List sx={{ width: '100%', bgcolor: 'background.paper'}} subheader={<ListSubheader>Team Invites</ListSubheader>}>
          { invites.map(invite => {
            return (
              <ListItem key={invite.teamId}
                secondaryAction={
                  <Button onClick={() => acceptInvite(invite)} disabled={isJoinDisabled} variant="contained" color='secondary'>Accept Invite</Button>
                }
              >
                <ListItemAvatar><EmailIcon /></ListItemAvatar>
                <ListItemText primary={invite.teamName}>{ invite.teamName }</ListItemText>
              </ListItem>
            )
          })}
        </List>
      </>
    )
  }

  return (
    <div>
      <Nav currentPage="Team Invites" />
      <Container style={{ marginTop: 20 }}>
        <Typography style={{ marginBottom: 10 }} variant="h4">Team Invites</Typography>
        { hasError && (
          <Alert severity="error">There was an error joining the team</Alert>
        )}
        { renderBody() }
      </Container>
    </div>
  )
}