import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from '../lib/contextLib';
import { Auth } from 'aws-amplify';
// import Auth from '@aws-amplify/auth';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Upstream Alerts
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function ForgotPassword() {

  const { userHasAuthenticated } = useAppContext();
  const nav = useNavigate();

  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  function validateCodeForm() {
    return email.length > 0;
  }

  function validateResetForm() {
    return code.length > 0 && password.length > 0 && password === confirmPassword;
  }

  async function handleSendCodeClick(e) {
    e.preventDefault();
    setIsSendingCode(true);
    setIsLoading(true);
    try {
      await Auth.forgotPassword(email);
      setCodeSent(true);
    } catch (e) {
      setIsSendingCode(false);
      setIsLoading(false);
      setHasError(true);
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  function renderRequestCodeForm() {
    return (
      <div>
        <Typography component="h1" variant="h5" style={{ textAlign: 'center'}}>
          Forgot Password
        </Typography>
        <Box component="form" onSubmit={handleSendCodeClick} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={handleSendCodeClick}
            disabled={!validateCodeForm()}
            sx={{ mt: 3, mb: 2 }}
          >
            { isLoading ? <CircularProgress style={{ color: 'white'}} /> : 'Request Password Reset' }
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                Login
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </div>
    )
  }

  async function handleConfirmClick(e) {
    e.preventDefault();
    setIsConfirming(true);
    setIsLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      setConfirmed(true);
    } catch (e) {
      console.error(e);
      setIsConfirming(false);
    } finally {
      setIsLoading(false);
      setIsConfirming(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <div>
        <Typography component="h1" variant="h5" style={{ textAlign: 'center'}}>
          Forgot Password
        </Typography>
        <Typography component="p" variant="paragraph" style={{ textAlign: 'center'}}>
          Please check your email { email } for the confirmation code
        </Typography>
        <Box component="form" onSubmit={handleConfirmClick} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="New Password"
            name="newPassword"
            autoComplete="password"
            helperText="Password must be at least 8 characters long"
            type="password"
            autoFocus
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            type="password"
            label="Confirm New Password"
            name="confirmNewPassword"
            autoComplete="password"
            autoFocus
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="confirmationCode"
            label="Confirmation Code"
            name="confirmationCode"
            autoComplete="confirmationCode"
            autoFocus
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!validateResetForm()}
            onClick={handleConfirmClick}
            sx={{ mt: 3, mb: 2 }}
          >
            { isLoading ? <CircularProgress style={{ color: 'white'}} /> : 'Submit Confirmation Code' }
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                Login
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </div>
    )
  }

  function renderSuccessMessage() {
    return (
      <div>
        <Typography component="h1" variant="h5" style={{ textAlign: 'center'}}>
          Your password has been reset!
        </Typography>
        <Link href="/login" variant="body2">
          Login
        </Link>
      </div>
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        { hasError && 
        (
          <Alert severity="error">There was an error resetting your password.</Alert>
        )}
        { !codeSent 
          ? renderRequestCodeForm()
          : !confirmed
          ? renderConfirmationForm()
          : renderSuccessMessage()
        }
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
