import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import CircularProgress from '@mui/material/CircularProgress';
import ChatIcon from '@mui/icons-material/Chat';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import { API } from 'aws-amplify';
import { useAppContext } from '../../lib/contextLib';
import { ButtonGroup } from '@mui/material';

export default function SlackNotifications() {
  const { currentTeam } = useAppContext();
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [channels, setChannels] = useState([]);
  const [isLinked, setIsLinked] = useState(false);
  const [showNewSlackChannel, setShowNewSlackChannel] = useState(false);
  const [availableChannels, setAvailableChannels] = useState([]);
  const [newChannelState, setNewChannelState] = useState('');
  const [newChannel, setNewChannel] = useState('');
  const [disableDeleteButton, setDisableDeleteButton] = useState(false);
  const [disableTestMessageButton, setDisableTestMessageButton] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState(false);
  const [confirmUnlink, setConfirmUnlink] = useState(false);
  const [unlinkAccountState, setUnlinkAccountState] = useState('');
  const [errorTesting, setErrorTesting] = useState(false);


  useEffect(() => {
    async function get() {
      try {
        let [slackResult, channels] = await Promise.all([API.get('api', '/notifications/slack', {
          queryStringParameters: {
            teamId: currentTeam
          }
        }), API.get('api', '/notifications/slack/channels', {
          queryStringParameters: {
            teamId: currentTeam
          }
        })]);
        setChannels(slackResult.channels);
        setIsLinked(slackResult.isLinked);
        setAvailableChannels(channels);
      } catch (e) {
        console.error('Error getting the notifications', e);
      } finally {
        setShouldRefresh(false);
        setIsLoading(false);
      }
    }
    if (shouldRefresh) {
      get();
    }

  }, [shouldRefresh])

  async function addNewChannel() {
    try {
      setNewChannelState('loading');
      await API.post('api', '/notifications/slack/create-channel', {
        body: {
          channel: newChannel,
          teamId: currentTeam
        }
      });
      setShouldRefresh(true);
      setNewChannelState('success');
      setNewChannel('');
      setShowNewSlackChannel(false)
    } catch (e) {
      console.error('Errro adding the channel', e);
      setNewChannelState('error');
    } finally {
      setTimeout(() => {
        setNewChannelState('');
      }, 2000);
    }
  }

  async function deleteChannel(channel) {
    try {
      setDisableDeleteButton(true);
      await API.post('api', '/notifications/slack/remove-channel', {
        body: {
          channel: channel,
          teamId: currentTeam
        }
      });
      setShouldRefresh(true);
      setDisableDeleteButton(false);
    } catch (e) {
      console.error('Error deleting the channel', e);
      setErrorDeleting(true);
      setTimeout(() => {
        setErrorDeleting(false);
      }, 3000)
    }
  }

  async function sendTestMessage(channel) {
    try {
      setDisableTestMessageButton(true);
      await API.post('api', '/notifications/slack/test-channel', {
        body: {
          channel: channel,
          teamId: currentTeam
        }
      });
      setShouldRefresh(true);
      setDisableTestMessageButton(false);
    } catch (e) {
      console.error('Error sending the test message', e);
      setErrorTesting(true);
      setTimeout(() => {
        setErrorTesting(false);
      }, 3000);
    }
  }

  async function unlinkSlackAccount() {
    try {
      setUnlinkAccountState('loading');
      await API.get('api', '/slack/unlink', {
        queryStringParameters: {
            teamId: currentTeam
          }
      });
      setUnlinkAccountState('success');
      setShouldRefresh(true);
      setConfirmUnlink(false);
    } catch (e) {
      console.error('Error unlinking the slack account', e);
      setUnlinkAccountState('error');
    } finally {
      setTimeout(() => {
        setUnlinkAccountState('');
        setConfirmUnlink(false);
      }, 3000);
    }
  }

  if (isLoading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div>
      { errorDeleting && (
        <Alert severity="error">There was an error deleting the Slack Channel.</Alert>
      )}
      { errorTesting && (
        <Alert severity="error">There was an error testing the Slack Channel.</Alert>
      )}
      <List sx={{ width: '100%', bgcolor: 'background.paper'}} subheader={<ListSubheader>Slack Notifications</ListSubheader>}>
        { channels.map((channel, idx) => {
          return (
            <ListItem key={idx} secondaryAction={
              <ButtonGroup>
                <Button variant="contained" onClick={() => sendTestMessage(channel)} disabled={disableTestMessageButton}>Send Test Message</Button>
                <Button variant="contained" onClick={() => deleteChannel(channel)} disabled={disableDeleteButton} color="error">Delete Channel</Button>
              </ButtonGroup>
            }>
              <ListItemAvatar>
                <ChatIcon />
              </ListItemAvatar>
              <ListItemText primary={`#${channel}`} secondary="Notificiations Enabled" >#{ channel }</ListItemText>
            </ListItem>
          )
        })}
      </List>
      { isLinked && (
        <div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
              <Fab color="secondary" aria-label="add" variant="extended" onClick={() => setShowNewSlackChannel(true)}>
                <AddIcon /> Add Slack Channel
              </Fab>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
            <Typography onClick={() => setConfirmUnlink(true)} variant="caption" style={{ fontStyle: 'italic', textAlign: 'center', cursor: 'pointer'}}>Unlink Slack Account</Typography>
          </div>
        </div>
      )}
      { !isLinked && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30}}>
          <a target="_blank" href="https://slack.com/oauth/v2/authorize?client_id=4402522842340.4418352885713&scope=chat:write,channels:read,channels:join,groups:read,mpim:read,im:read&user_scope&redirect_uri=https://api.upstreamalerts.com/slack/oauth_redirect" rel="noreferrer"><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
        </div>
      )}
      <Dialog open={showNewSlackChannel} onClose={() => setShowNewSlackChannel(false)}>
        <DialogTitle>Add Slack Channel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a new Slack channel that you want to get notifications for.
          </DialogContentText>
          <Autocomplete
            style={{ marginTop: 10, marginBottom: 40 }}
            fullWidth
            disablePortal
            value={newChannel}
            onChange={(event, newValue) => setNewChannel(newValue || '')}
            id="combo-box-demo"
            options={availableChannels}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Channel" />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNewSlackChannel(false)}>Cancel</Button>
          <Button onClick={addNewChannel} disabled={newChannelState === 'loading' || !newChannel.length > 0}>{ newChannelState === 'loading' ? <CircularProgress /> : 'Add Channel'}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmUnlink} onClose={() => setConfirmUnlink(false)}>
        <DialogTitle>Unlink Slack Account</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to unlink your Slack account from Upstream Alerts?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmUnlink(false)}>Cancel</Button>
          <Button onClick={unlinkSlackAccount} disabled={unlinkAccountState === 'loading'}>{ unlinkAccountState === 'loading' ? <CircularProgress /> : 'Unlink Account'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )

}