import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import EmailIcon from '@mui/icons-material/Email';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';

import validator from 'validator'

import { API } from 'aws-amplify';

import { useAppContext } from '../../lib/contextLib';

export default function EmailNotifications() {
  const { currentTeam } = useAppContext();

  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [emailNotifications, setEmailNotifications] = useState([]);
  const [disableEmailButton, setDisableEmailButton] = useState(false);
  const [showNewEmail, setShowNewEmail] = useState(false);
  const [newEmailState, setNewEmailState] = useState('');
  const [newEmail, setNewEmail] = useState('');

  useEffect(() => {
    async function getInfo() {
      try {
        setIsLoading(true);
        let notifications = await API.get('api', '/notifications/email', {
          queryStringParameters: {
            teamId: currentTeam
          }
        });
        console.log('notifications', notifications);
        setEmailNotifications(notifications);
      } catch (e) {
        console.error('Error getting the alerts', e);
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    }
    console.log('should refresh 2', shouldRefresh);
    if (shouldRefresh) {
      getInfo();
    }
  }, [shouldRefresh, currentTeam]);

  let isValidNewEmail = validator.isEmail(newEmail);

  async function handleSubmitNewEmail() {
    try {
      setNewEmailState('loading');
      setDisableEmailButton(true);
      await API.post('api', '/notifications/email/create', {
        body: {
          email: newEmail,
          teamId: currentTeam
        }
      });
      let notifications = await API.get('api', '/notifications/email', {
        queryStringParameters: {
            teamId: currentTeam
          }
      });
      setEmailNotifications(notifications);
      setNewEmailState('');
      setNewEmail('');
      setShowNewEmail(false);
    } catch (e) {
      console.error('Error adding a new email', e);
      setNewEmailState('error');
    } finally {
      setDisableEmailButton(false);
      setTimeout(() => {
        setNewEmailState('');
      });
    }
  }

  async function deleteEmail(email) {
    try {
      setDisableEmailButton(true);
      await API.post('api', '/notifications/email/delete', {
        body: {
          email: email,
          teamId: currentTeam
        }
      });
      let notifications = await API.get('api', '/notifications/email', {
        queryStringParameters: {
            teamId: currentTeam
          }
      });
      setEmailNotifications(notifications);
    } catch (e) {
      console.error('Error deleting the email', e);
    } finally {
      setDisableEmailButton(false);
    }
  }

  async function toggleEmail(email, current) {
    try {
      setDisableEmailButton(true);
      await API.post('api', '/notifications/email/toggle', {
        body: {
          email: email,
          enabled: !current,
          teamId: currentTeam
        }
      });
      let notifications = await API.get('api', '/notifications/email', {
        queryStringParameters: {
            teamId: currentTeam
          }
      });
      setEmailNotifications(notifications);
    } catch (e) {
      console.error('Error toggling the email', e);
    } finally {
      setDisableEmailButton(false);
    }
  }

  function renderBody() {
    if (isLoading) {
       return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
          <CircularProgress />
        </div>
      )
    }

    return (
      <div>
        <Alert severity="info">When a service you enabled has a status change, you will receive a notification to what is configured below.</Alert>
        <List sx={{ width: '100%', bgcolor: 'background.paper'}} subheader={<ListSubheader>Email Notifications</ListSubheader>}>
          { emailNotifications.map(notif => {
            return (
              <ListItem 
                key={notif.email}
                secondaryAction={
                  <ButtonGroup>
                    <Button onClick={() => toggleEmail(notif.email, notif.enabled)} disabled={disableEmailButton} variant={notif.enabled ? 'contained' : 'outlined'}>{ notif.enabled ? 'Stop Notifications' : 'Start Notifications' }</Button>
                    <Button onClick={() => deleteEmail(notif.email)} disabled={disableEmailButton} color="error" variant={notif.enabled ? 'contained' : 'outlined'}>Delete Email</Button>
                  </ButtonGroup>
                }>
                <ListItemAvatar>
                  <EmailIcon />
                </ListItemAvatar>
                <ListItemText primary={notif.email} secondary={notif.enabled ? 'Notifications Enabled' : 'Notifications Disabled'}>{ notif.email } </ListItemText>
              </ListItem>
            )
          })}
        </List>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
          <Fab color="secondary" aria-label="add" variant="extended" onClick={() => setShowNewEmail(true)}>
            <AddIcon /> Add Email
          </Fab>
        </div>
      </div>
    )
  }

  return (
    <div>
      { renderBody() }
      <Dialog open={showNewEmail} onClose={() => setShowNewEmail(false)}>
        <DialogTitle>Add Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add an email address that you want to get notifications for.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            error={!isValidNewEmail}
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNewEmail(false)}>Cancel</Button>
          <Button disabled={newEmailState === 'loading' || !isValidNewEmail} onClick={handleSubmitNewEmail}>{ newEmailState === 'loading' ? <CircularProgress /> : 'Add Email'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}