import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import ButtonGroup from '@mui/material/ButtonGroup';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import EmailIcon from '@mui/icons-material/Email';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import validator from 'validator'

import { API, Auth } from 'aws-amplify';

import Nav from '../shared/Nav';
import { useAppContext } from '../lib/contextLib';
import CreateAlert from '../shared/CreateAlert';

export default function Settings() {

  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  
  const [members, setMembers] = useState([]);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [showNewUser, setShowNewUser] = useState(false);
  const [newEmailState, setNewEmailState] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newUserState, setNewUserState] = useState('');
  const [isRemoveDisabled, setIsRemoveDisabled] = useState(false);
  const [didResendInvite, setDidResendInvite] = useState(false);

  const { currentTeam } = useAppContext();

  useEffect(() => {
    async function getInfo() {
      try {
        setIsLoading(true);
        let teamMembers = await API.get('api', '/team/members', {
          queryStringParameters: {
            teamId: currentTeam
          }
        });
        console.log('results', teamMembers);
        setMembers(teamMembers.members);
        setInvitedMembers(teamMembers.invitedMembers);
        setIsLoading(false);
      } catch (e) {
        console.error('Error getting the alerts', e);
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    }
    console.log('should refresh 2', shouldRefresh);
    if (shouldRefresh) {
      getInfo();
    }
  }, [currentTeam, shouldRefresh]);

  async function handleSubmitNewUser() {
    try {
      setNewUserState('loading');
      await API.post('api', '/team/members/new', {
        body: {
          email: newEmail,
          teamId: currentTeam
        }
      });
      setShouldRefresh(true);
      setNewEmail('');
      setShowNewUser(false);
      setNewUserState('');
    } catch (e) {
      console.error('There was an error adding the new team member', e);
      setNewUserState('error');
    } finally {
      setTimeout(() => {
        setNewUserState('');
      }, 2000);
    }
  }

  async function removeMember(member) {
    try {
      setIsRemoveDisabled(true);
      await API.post('api', '/team/members/remove', {
        body: {
          teamId: currentTeam,
          userId: member.userId
        }
      });
      setShouldRefresh(true);
    } catch (e) {
      console.error('There was an error removing the member', e);
    } finally {
      setIsRemoveDisabled(false);
    }
  }
  
  async function resendInvite(member) {
    try {
      setIsRemoveDisabled(true);
      await API.post('api', '/team/members/resend-invite', {
        body: {
          teamId: currentTeam,
          emailAddress: member.emailAddress
        }
      });
      setShouldRefresh(true);
      setDidResendInvite(true);
    } catch (e) {
      console.error('There was an error resending the invite', e);
    } finally {
      setIsRemoveDisabled(false);
      setTimeout(() => {
        setDidResendInvite(false);
      }, 3000);
    }
  }

  function renderBody() {
    if (isLoading) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
          <CircularProgress style={{ color: 'white' }} />
        </div>
      )
    }
    return (
      <>
        { invitedMembers.length > 0 && (
          <List sx={{ width: '100%', bgcolor: 'background.paper'}} subheader={<ListSubheader>Invited Members</ListSubheader>}>
            { invitedMembers.map(member => {
              return (
                <ListItem key={member.emailAddress}
                  secondaryAction={
                    <ButtonGroup>
                      <Button disabled={isRemoveDisabled} onClick={() => removeMember(member)} variant="contained" color='error'>Cancel Invite</Button>
                      <Button disabled={isRemoveDisabled} onClick={() => resendInvite(member)} variant="contained" color="secondary">Resend Invite</Button>
                    </ButtonGroup>
                  }
                >
                  <ListItemAvatar><EmailIcon /></ListItemAvatar>
                  <ListItemText primary={member.emailAddress}>{ member.emailAddress }</ListItemText>
                </ListItem>
              )
            })}
          </List>
        )}
        <List sx={{ width: '100%', bgcolor: 'background.paper'}} subheader={<ListSubheader>Team Members</ListSubheader>}>
          { members.map(member => {
            return (
              <ListItem key={member.emailAddress}
                secondaryAction={
                  <Button onClick={() => removeMember(member)} disabled={isRemoveDisabled || members.length === 1} variant="contained" color='error'>Remove User</Button>
                }
              >
                <ListItemAvatar><EmailIcon /></ListItemAvatar>
                <ListItemText primary={member.emailAddress}>{ member.emailAddress }</ListItemText>
              </ListItem>
            )
          })}
        </List>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
          <Fab color="secondary" aria-label="add" variant="extended" onClick={() => setShowNewUser(true)}>
            <AddIcon /> Add Team Member
          </Fab>
        </div>
      </>
    )
    return null;
  }

  let isValidNewEmail = validator.isEmail(newEmail);

  return (
    <div>
      <Nav currentPage="Team Settings" />
      <Container style={{ marginTop: 20 }}>
        <Typography style={{ marginBottom: 10 }} variant="h4">Team Settings</Typography>
        { didResendInvite && (
          <Alert severity="success">The invite has been resent.</Alert>
        )}
        { renderBody() }
        <Dialog open={showNewUser} onClose={() => setShowNewUser(true)}>
          <DialogTitle>Add Member</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add a new team member by their email address. They will get an email prompting them to join your team. <strong>Every team member is $10/month/user</strong>
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              error={!isValidNewEmail}
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowNewUser(false)}>Cancel</Button>
            <Button disabled={newUserState === 'loading' || !isValidNewEmail} onClick={handleSubmitNewUser}>{ newUserState === 'loading' ? <CircularProgress style={{ color: 'white' }} />: 'Add Team Member'}</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  )
}