import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AppContext, useAppContext } from './lib/contextLib';
import { Auth } from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import Notifications from './pages/Notifications';
import SlackLink from './pages/SlackLink';
import TeamSettings from './pages/TeamSettings';
import TeamInvites from './pages/TeamInvites';
import ForgotPassword from './pages/ForgotPassword';

import './style.css';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#61b4f4',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ef6c00',
    },
  },
});

export default function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [currentTeam, setCurrentTeam] = useState(null);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      let t = localStorage.getItem('currentTeamId');
      if (t === 'null') {
        t = null;
      }
      setCurrentTeam(t);
    } catch (e) {
      console.error('No current user', e);
      userHasAuthenticated(false);
    }
    setIsAuthenticating(false);
  }

  async function switchTeam(teamId) {
    setCurrentTeam(teamId);
    // Write the current team id to localstorage
    localStorage.setItem('currentTeamId', teamId);
  }

  if (isAuthenticating) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress />
      </div>
    )
  }

  function PrivateRoute({ children }) {
    const { isAuthenticated } = useAppContext();
    console.log('is authenticated', isAuthenticated);
    return isAuthenticated ? children : <Navigate to="/login" />;
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, currentTeam, switchTeam }}>
          <Routes>
            <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/team/settings" element={<PrivateRoute><TeamSettings /></PrivateRoute>} />
            <Route path="/team/invites" element={<PrivateRoute><TeamInvites /></PrivateRoute>} />
            <Route path="/notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
            <Route path="/slack/link/:nonce" element={<PrivateRoute><SlackLink /></PrivateRoute>} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        </AppContext.Provider>
      </ThemeProvider>
    </div>
  );
}
