import React, { useState, useEffect } from 'react';

import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Menu from '@mui/material/Menu';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import { API } from 'aws-amplify';

import { useAppContext } from '../lib/contextLib';

export default function TeamNavbarButton() {

  const { currentTeam, switchTeam } = useAppContext();

  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [teams, setTeams] = useState([]);
  const [currentTeamName, setCurrentTeamName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openNewTeam, setOpenNewTeam] = useState(false);
  const [newTeamName, setNewTeamName] = useState('');
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [newTeamError, setNewTeamError] = useState(false);

  useEffect(() => {
    let matchedTeam = teams.find(elem => elem.teamId === currentTeam);
    if (matchedTeam) {
      setCurrentTeamName(matchedTeam.teamName);
    }
  }, [currentTeam, teams]);

  useEffect(() => {
    async function getTeams() {
      try {
        let result = await API.get('api', '/teams');
        setTeams(result || []);
      } catch (e) {
        console.error('Error getting the teams', e);
      } finally {
        setIsLoading(false);
      }
    }
    if (shouldRefresh) {
      getTeams();
      setShouldRefresh(false);
    }
  }, [shouldRefresh])

  function openTeamMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function saveNewTeam() {
    try {
      setIsSaveLoading(true);
      await API.post('api', '/teams/new', {
        body: {
          teamName: newTeamName
        }
      });
      setShouldRefresh(true);
      setNewTeamName('');
      setOpenNewTeam(false);
    } catch (e) {
      console.error('There was an error saving the new team', e);
      setNewTeamError(true);
    } finally {
      setIsSaveLoading(false);
      setTimeout(() => {
        setNewTeamName(false);
      }, 3000)
    }
  }

  if (isLoading) {
    return <Skeleton variant='rectangle' height={30} width={100} sx={{ borderRadius: 1, my: 3, mr: 2 }}/>
  }

  let addText = teams.length > 0 ? 'Team Options' : 'Add Team';

  let name = currentTeamName.length > 0 ? currentTeamName : addText;

  return (
    <div>
      <Tooltip title="Open Teams">
        <Link sx={{ textDecoration: 'none'}}>
          { isLoading ? <Skeleton /> : 
            <Button 
            sx={{my: 2,
              color: 'black',
              textTransform: 'capitalize',
              fontSize: 16,
              fontWeight: '400',
              borderBottomStyle: 'none',
              marginRight: 2,
            }}
            endIcon={<KeyboardArrowDownIcon />}
            onClick={openTeamMenu}
            >{ name }</Button>
          }
        </Link>
      </Tooltip>
          <Menu
        anchorEl={anchorEl}
        id="team-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => switchTeam(null)}>
          <Avatar sx={{ mr: 1}}></Avatar>Personal Account
        </MenuItem>
        <Divider />

        { teams.map(team => {
          return (
            <MenuItem key={team.id} onClick={() => switchTeam(team.teamId)}>
              <Avatar sx={{ mr: 1}}>{ team.teamName.charAt(0) }</Avatar>{ team.teamName}
            </MenuItem>
          )
        })}
        { teams.length > 0 && (
          <Divider />
        )}
        <MenuItem>
          <Button onClick={() => setOpenNewTeam(true)} sx={{ color: '#15141A', textTransform: 'capitalize'}}><PersonAdd fontSize="small" sx={{ marginRight: 1}} />Create Team</Button>
        </MenuItem>
      </Menu>
      <Dialog open={openNewTeam}  onClose={() => setOpenNewTeam(false)}>
        <DialogTitle>Create New Team</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create a new team to let other users manage services and notifications. You can configure payment options after you create a team.
          </DialogContentText>
          <TextField
            autoFocus
            label="Team Name"
            type="text"
            fullWidth
            variant="standard"
            value={newTeamName}
            onChange={(e) => setNewTeamName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNewTeam(false)}>Cancel</Button>
          <Button variant="contained" onClick={saveNewTeam}>{ isSaveLoading ? <CircularProgress style={{ color: 'white' }} />: 'Create New Team'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
};