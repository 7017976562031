import React, { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import WebhookIcon from '@mui/icons-material/Webhook';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';

import validator from 'validator'

import { API } from 'aws-amplify';

import { useAppContext } from '../../lib/contextLib';

export default function WebhookNotifications() {
  const { currentTeam } = useAppContext();

  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [webhookNotifications, setWebhookNotifications] = useState([]);
  const [disableWebhookButton, setDisableWebhookButton] = useState(false);
  const [showNewWebhook, setShowNewWebhook] = useState(false);
  const [newWebhookState, setNewWebhookState] = useState('');
  const [newWebhook, setNewWebhook] = useState('');

  useEffect(() => {
    async function getInfo() {
      try {
        setIsLoading(true);
        let notifications = await API.get('api', '/notifications/webhook', {
          queryStringParameters: {
            teamId: currentTeam
          }
        });
        console.log('notifications', notifications);
        setWebhookNotifications(notifications);
      } catch (e) {
        console.error('Error getting the alerts', e);
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    }
    console.log('should refresh 2', shouldRefresh);
    if (shouldRefresh) {
      getInfo();
    }
  }, [shouldRefresh, currentTeam]);

  async function handleSubmitNewWebhook() {
    try {
      setNewWebhookState('loading');
      setDisableWebhookButton(true);
      await API.post('api', '/notifications/webhook/create', {
        body: {
          destination: newWebhook,
          teamId: currentTeam
        }
      });
      let notifications = await API.get('api', '/notifications/webhook', {
        queryStringParameters: {
            teamId: currentTeam
          }
      });
      setWebhookNotifications(notifications);
      setNewWebhookState('');
      setNewWebhook('');
      setShowNewWebhook(false);
    } catch (e) {
      console.error('Error adding a new webhook', e);
      setNewWebhookState('error');
    } finally {
      setDisableWebhookButton(false);
      setTimeout(() => {
        setNewWebhookState('');
      });
    }
  }

  async function deleteWebhook(destination) {
    try {
      setDisableWebhookButton(true);
      await API.post('api', '/notifications/webhook/delete', {
        body: {
          destination: destination,
          teamId: currentTeam
        }
      });
      let notifications = await API.get('api', '/notifications/webhook', {
        queryStringParameters: {
            teamId: currentTeam
          }
      });
      setWebhookNotifications(notifications);
    } catch (e) {
      console.error('Error deleting the webhook', e);
    } finally {
      setDisableWebhookButton(false);
    }
  }

  async function toggleWebhook(webhook, current) {
    try {
      setDisableWebhookButton(true);
      await API.post('api', '/notifications/webhook/toggle', {
        body: {
          destination: webhook,
          enabled: !current,
          teamId: currentTeam
        }
      });
      let notifications = await API.get('api', '/notifications/webhook', {
        queryStringParameters: {
            teamId: currentTeam
          }
      });
      setWebhookNotifications(notifications);
    } catch (e) {
      console.error('Error toggling the webhook', e);
    } finally {
      setDisableWebhookButton(false);
    }
  }

  function renderBody() {
    if (isLoading) {
       return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
          <CircularProgress />
        </div>
      )
    }

    return (
      <div>
        <List sx={{ width: '100%', bgcolor: 'background.paper'}} subheader={<ListSubheader>Webhook Notifications</ListSubheader>}>
          { webhookNotifications.map(notif => {
            return (
              <ListItem 
                key={notif.destination}
                secondaryAction={
                  <ButtonGroup>
                    <Button onClick={() => toggleWebhook(notif.destination, notif.enabled)} disabled={disableWebhookButton} variant={notif.enabled ? 'contained' : 'outlined'}>{ notif.enabled ? 'Stop Notifications' : 'Start Notifications' }</Button>
                    <Button onClick={() => deleteWebhook(notif.destination)} disabled={disableWebhookButton} color="error" variant={notif.enabled ? 'contained' : 'outlined'}>Delete Webhook</Button>
                  </ButtonGroup>
                }>
                <ListItemAvatar>
                  <WebhookIcon />
                </ListItemAvatar>
                <ListItemText primary={notif.destination} secondary={notif.enabled ? 'Notifications Enabled' : 'Notifications Disabled'}>{ notif.destination } </ListItemText>
              </ListItem>
            )
          })}
        </List>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
          <Fab color="secondary" aria-label="add" variant="extended" onClick={() => setShowNewWebhook(true)}>
            <AddIcon /> Add Webhook
          </Fab>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
          <Typography variant="caption" style={{ fontStyle: 'italic', textAlign: 'center', cursor: 'pointer'}}><a style={{ textDecoration: 'none' }} target="_blank" href="https://upstreamalerts.com/blog/webhooks/">Webhook Docs</a></Typography>
        </div>
      </div>
    )
  }

  return (
    <div>
      { renderBody() }
      <Dialog open={showNewWebhook} onClose={() => setShowNewWebhook(false)}>
        <DialogTitle>Add Webhook</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a webhook that you want to get notifications for. We will send a POST request to your destination.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Destination"
            type="text"
            fullWidth
            variant="standard"
            value={newWebhook}
            onChange={(e) => setNewWebhook(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNewWebhook(false)}>Cancel</Button>
          <Button disabled={newWebhookState === 'loading'} onClick={handleSubmitNewWebhook}>{ newWebhookState === 'loading' ? <CircularProgress /> : 'Add Webhook'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}