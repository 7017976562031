import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';

import { useParams, useNavigate } from 'react-router-dom';

import { API } from 'aws-amplify';

import Nav from '../shared/Nav';

export default function SlackLink() {

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);

  let { nonce } = useParams();
  const nav = useNavigate();

  async function linkNonce() {
    try {
      setIsLoading(true);
      await API.post('api', '/slack/link', {
        body: {
          nonce: nonce
        }
      });
      nav('/notifications');
      setHasSuccess(true);

    } catch (e) {
      console.error('Error linking to slack', e);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }
  
  function renderBody() {
    if (isLoading) {
      return (
        <div style={{ textAlign: 'center'}}>
          <Typography variant="h5">Linking Slack...</Typography>
          <CircularProgress style={{ marginTop: 10 }} />
        </div>
      )
    }
    if (hasError) {
      return (
      <Alert severity='error'>There was an error linking your Slack account to UpstreamAlerts.</Alert>
      )
    }
    if (hasSuccess) {
      return (
        <Alert severity='sucesss'>Your Slack account has been linked! <Link to="/notifications">Configure.</Link></Alert>
      )
    }
    return (
      <div style={{ textAlign: 'center'}}>
        <Typography style={{ marginBottom: 10 }} variant="h5">Linking your Slack account to Upstream Alerts</Typography>
        <Button onClick={linkNonce} variant="contained">Link Accounts</Button>
      </div>
    )
  }

  console.log('has success', hasSuccess);

  return (
    <div>
      <Nav />
      <Container style={{ marginTop: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            { renderBody() }
        </div>
      </Container>
    </div>
  )
}