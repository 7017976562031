import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';

import { Auth } from "aws-amplify";
import { useAppContext } from '../lib/contextLib';

import TeamNavbarButton from './TeamNavbarButton';
import logoWithName from '../assets/logo-with_name.png';

export default function Nav(props) {
  const { userHasAuthenticated, currentTeam } = useAppContext();
  const nav = useNavigate();

  const pages = [{
    name: 'Services',
    path: '/'
  }, {
    name: 'Notifications',
    path: '/notifications'
  }];

  if (currentTeam !== null) {
    pages.push({
      name: 'Team Settings',
      path: '/team/settings'
    });
  }
  const settings = ['Settings', 'Team Invites', 'Logout'];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  async function handleLogout(settingName) {
    switch (settingName) {
      case 'Logout':
        await Auth.signOut();
        userHasAuthenticated(false);
        return;
      case 'Settings':
        nav('/settings');
        return;
      case 'Team Invites':
        nav('/team/invites')
        return;
      default:
        return;
    }
  }

  function activeStyle(page) {
    console.log('page', page, props.currentPage);
    if (page === props.currentPage) {
      return {
        my: 2,
        color: '#61b4f4',
        textTransform: 'capitalize',
        display: 'block',
        fontSize: 16,
        fontWeight: '700',
        borderBottomStyle: 'solid',
        borderBottomWidth: 3,
        borderBottomColor: '#61b4f4',
        borderRadius: 0,
        marginRight: 2
      }
    } else {
      return {
        my: 2,
        color: 'black',
        textTransform: 'capitalize',
        display: 'block',
        fontSize: 16,
        fontWeight: '400',
        borderBottomStyle: 'none',
        marginRight: 2
      }
      
    }
  }
  

  return (
    <div className="navbar">
      <AppBar position="static" sx={{ backgroundColor: 'white', color: '#61b4f4', boxShadow: 'none'}}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
            <Link href="/">
              <img src={logoWithName} alt="Upstream Alerts" style={{
                  height: 60,
                  width: 'auto',
                  marginTop: -5,
                  marginRight: 10
              }}/>
            </Link>
            {/* <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 10,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'Roboto',
                fontWeight: 500,
                // letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Upstream Alerts
            </Typography> */}

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.name} onClick={handleCloseNavMenu} sx={{ color: 'black'}}>
                    <Link href={page.path} sx={{ color: 'black'}}><Typography textAlign="center" sx={activeStyle(page)}>{page.name}</Typography></Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Upstream Alerts
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <TeamNavbarButton />
              {pages.map((page) => (
                <Link href={page.path} sx={{ textDecoration: 'none'}}>
                  <Button
                    key={page.name}
                    onClick={handleCloseNavMenu}
                    sx={activeStyle(page.name)}
                  >
                    {page.name}
                  </Button>
                </Link>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AccountCircle style={{ color: '#61b4f4', fontSize: 30 }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography onClick={() => handleLogout(setting)} textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>

  );
}
