import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { API, Auth } from 'aws-amplify';

import Nav from '../shared/Nav';
import CreateAlert from '../shared/CreateAlert';

export default function Settings() {

  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [email, setEmail] = useState('');

  const [passwordState, setPasswordState] = useState('');
  const [emailState, setEmailState] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  useEffect(() => {
    async function getInfo() {
      try {
        setIsLoading(true);
        
      } catch (e) {
        console.error('Error getting the alerts', e);
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    }
    console.log('should refresh 2', shouldRefresh);
    if (shouldRefresh) {
      getInfo();
    }
  }, [shouldRefresh]);

  async function handleChangePassword() {
    try {
      setPasswordState('loading');
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        password,
        newPassword
      );
      setPasswordState('success');
    } catch (e) {
      console.error('Error changing the password', e);
      setPasswordState('error');
    } finally {
      setTimeout(() => {
        setPasswordState('');
      }, 2000);
    }
  }

  async function handleChangeEmail() {
    try {
      setEmailState('loading');
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(currentUser, {
        email: email
      });
      setShowConfirmation(true);
      setEmailState('success');
    } catch (e) {
      console.error('Error changing the password', e);
      setEmailState('error');
      setTimeout(() => {
        setEmailState('');
      }, 2000);
    }
  }

  async function handleConfirmationSubmit(e) {
    e.preventDefault();
    try {
      setIsConfirmLoading(true);
      await Auth.verifyCurrentUserAtrributeSubmit('email', confirmationCode);
      setEmailState('success');
      setIsConfirmLoading(false);
    } catch (e) {
      console.error('Error signing up', e);
      setEmailState('error');
    } finally {
       setTimeout(() => {
        setEmailState('');
      }, 2000);
    }
  }

  const isPasswordConfirmationValid = newPassword.length > 0 && newPasswordConfirmation !== newPassword;
  const isSavePasswordDisabled = password.length > 7 && newPassword.length > 7 && newPassword === newPasswordConfirmation;

  return (
    <div>
      <Nav currentPage="Settings" />
      <Container style={{ marginTop: 20 }}>
        <Typography style={{ marginBottom: 10 }} variant="h4">Settings</Typography>
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <Typography variant="h6">Change Password</Typography>
            <Alert severity="warning">
              <AlertTitle>Password Rules</AlertTitle>
              <ul>
                <li>8 Characters Long</li>
              </ul>
            </Alert>
            <div>
              <TextField
                style={{ marginTop: 15 }}
                value={password}
                required
                autoComplete='current-password'
                fullWidth
                type="password"
                onChange={(e) => setPassword(e.target.value)} 
                label="Current Password"  
              />
              <TextField
                style={{ marginTop: 15 }}
                value={newPassword}
                required
                type="password"
                fullWidth
                onChange={(e) => setNewPassword(e.target.value)} 
                label="New Password"  
              />
              <TextField
                error={isPasswordConfirmationValid}
                style={{ marginTop: 15 }}
                value={newPasswordConfirmation}
                required
                type="password"
                fullWidth
                onChange={(e) => setNewPasswordConfirmation(e.target.value)} 
                label="New Password Confirmation"  
              />
            <Button onClick={handleChangePassword} disabled={!isSavePasswordDisabled || passwordState === 'loading'} style={{ marginTop: 15 }} variant="contained">{ passwordState === 'loading' ? <CircularProgress style={{ color: 'white' }} /> : 'Change Password'}</Button>
            </div>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="h6">Change Email</Typography>
            <TextField
              style={{ marginTop: 15 }}
              value={email}
              required
              fullWidth
              type="email"
              onChange={(e) => setEmail(e.target.value)} 
              label="New Email"  
            />
            <Button onClick={handleChangeEmail} style={{ marginTop: 15 }} variant="contained">{ emailState === 'loading' ? <CircularProgress style={{ color: 'white' }} /> : 'Change Email'}</Button>
          </Grid>
        </Grid>
        <Dialog open={showConfirmation} onClose={() => setShowConfirmation(false)}>
          <DialogTitle>Confirm Email</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Confirm your email address. A code has been emailed to you.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Confirmation Code"
              type="text"
              fullWidth
              variant="standard"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowConfirmation(false)}>Cancel</Button>
            <Button onClick={handleConfirmationSubmit}>{ isConfirmLoading ? <CircularProgress style={{ color: 'white' }} /> : 'Confirm Code'}</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  )
}