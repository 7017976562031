import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Nav from '../shared/Nav';
import EmailNotifications from './notifications/EmailNotifications';
import SlackNotifications from './notifications/SlackNotifications';
import WebhookNotifications from './notifications/WebhookNotifications';

export default function Notifications() {

  return (
    <div>
      <Nav currentPage="Notifications" />
      <Container style={{ marginTop: 20 }}>
        <Typography style={{ marginBottom: 10 }} variant="h4">Notifications</Typography>
        <EmailNotifications />
        <SlackNotifications />
        <WebhookNotifications />
      </Container>
    </div>
  )
}