import React, { useState, useEffect, useRef, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import { API } from 'aws-amplify';
import Fuse from 'fuse.js'

import Nav from '../shared/Nav';
import CreateAlert from '../shared/CreateAlert';
import { useAppContext } from '../lib/contextLib';

export default function Dashboard() {
  const { currentTeam } = useAppContext();

  const [alerts, setAlerts] = useState([]);
  const [availableNotifiers, setAvailableNotifiers] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [disableAlertButtons, setDisableAlertButtons] = useState(false);
  const [activeNames, setActiveNames] = useState([]);
  const [portalUrl, setPortalUrl] = useState('');

  const [emailsToNotify, setEmailsToNotify] = useState([]);
  const [webhooksToNotify, setWebhooksToNotify] = useState([]);
  const [slackChannelsToNotify, setSlackChannelsToNotify] = useState([]);
  const [query, setQuery] = useState('');

  const fuseRef = useRef(null);

  const isTeam = currentTeam !== null && currentTeam !== undefined;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    async function getInfo() {
      try {
        setIsLoading(true);
        let [alerts, checkoutOrPortal, emailNotifications, slackNotifications, webhookNotifications] = await Promise.all([API.get('api', '/notifiers', {
          queryStringParameters: {
            teamId: currentTeam
          }
        }), API.get('api', '/billing/checkout-or-portal', {
          queryStringParameters: {
            teamId: currentTeam
          }
        }), API.get('api', '/notifications/email', {
          queryStringParameters: {
            teamId: currentTeam
          }
        }), API.get('api', '/notifications/slack', {
          queryStringParameters: {
            teamId: currentTeam
          }
        }), API.get('api', '/notifications/slack', {
          queryStringParameters: {
            teamId: currentTeam
          }
        })]);
        console.log('available alerts', alerts, checkoutOrPortal, emailNotifications);
        setAlerts(alerts.notifiers);
        setActiveNames(alerts.notifiers.map(elem => elem.notifierName));
        setAvailableNotifiers(alerts.allNotifiers);
        fuseRef.current = new Fuse(alerts.allNotifiers, {
          keys: ['name']
        });
        setPortalUrl(checkoutOrPortal.session.url);
        console.log('is unlimited', checkoutOrPortal.billingPlan === 'unlimited');
        setIsUnlimited(checkoutOrPortal.billingPlan === 'unlimited' || checkoutOrPortal.billingPlan === 'team-unlimited');
        setEmailsToNotify(emailNotifications);
        setSlackChannelsToNotify(slackNotifications.channels);
        setWebhooksToNotify(webhookNotifications);
      } catch (e) {
        console.error('Error getting the alerts', e);
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    }
    console.log('should refresh 2', shouldRefresh);
    if (shouldRefresh) {
      getInfo();
    }
  }, [shouldRefresh, currentTeam]);

  const searchedNotifiers = useMemo(() => {
    // return availableNotifiers;
    if (query === '') {
      return availableNotifiers;
    } else {
      return fuseRef.current.search(query).map(elem => elem.item);
    }
  }, [availableNotifiers, query]);

  async function enableAlert(name) {
    setDisableAlertButtons(true);
    try {
      await API.post('api', '/notifiers/toggle', {
        body: {
          name: name,
          teamId: currentTeam
        },
      });
      console.log('should refresh');
      let alerts = await API.get('api', '/notifiers', {
        queryStringParameters: {
            teamId: currentTeam
          }
      });
      setAlerts(alerts.notifiers);
      setActiveNames(alerts.notifiers.map(elem => elem.notifierName));
      setDisableAlertButtons(false);
    } catch (e) {
      console.error('Error updating that notifier', e);
    }
  }

  function renderAlerts() {
    if (isLoading) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
          <CircularProgress />
        </div>
      )
    }

    console.log((currentPage - 1) * 10, ((currentPage - 1) * 10) + 10);
    
    return (
      <div>
        { !isUnlimited && isTeam && (
          <Alert severity='info'>
            <AlertTitle>To activate your team, please <Link target='_blank' href={portalUrl}>configure your billing account.</Link></AlertTitle>
            You will be billed $10/month per member of your team. You can manage your team members <Link href="/team/settings">here.</Link>
          </Alert>
        )}
        { !isUnlimited && !isTeam && (
          <Alert severity='info'>
            <AlertTitle>Only { 3 - alerts.length } free services remaining. <Link target="_blank" href={portalUrl}>Click here to get unlimited!</Link></AlertTitle>
              { emailsToNotify.length === 0 && slackChannelsToNotify.length === 0 && (
                <>
                  You don't have any active notifications. You can configure them <Link href="/notifications">here</Link>.
                </>
              )}
              { (emailsToNotify.length > 0 || slackChannelsToNotify.length > 0) && (
                <> When a service you have enabled for experiences an outage, or changes their status in any way, we'll send notifications to:</>
              )}
              { emailsToNotify.length > 0 && (
                <>
                  <br /><br /><Typography variant='p' sx={{ fontWeight: 500}}>Email</Typography>
                  <ul>
                    { emailsToNotify.map(email => {
                      return <li key={email.email}>{ email.email }</li>
                    })}
                  </ul>
                </>
              )}
              { slackChannelsToNotify.length > 0 && (
                <>
                  <Typography variant='p' sx={{ fontWeight: 500}}>Slack</Typography>
                  <ul>
                    { slackChannelsToNotify.map(channel => {
                      return <li key={channel}>{ channel }</li>
                    })}
                  </ul>
                </>
              )}
              { webhooksToNotify.length > 0 && (
                <>
                  <Typography variant='p' sx={{ fontWeight: 500}}>Webhooks</Typography>
                  <ul>
                    { webhooksToNotify.map(destination => {
                      return <li key={destination}>{ destination }</li>
                    })}
                  </ul>
                </>
              )}
              <Link href="/notifications">Configure more options.</Link>
          </Alert>
        )}
        {
          isUnlimited && !isTeam && (
            <Alert severity='success'>
              <AlertTitle>You have unlimited services! <Link target="_blank" href={portalUrl}>Click here to manage your account</Link></AlertTitle>
              { emailsToNotify.length === 0 && slackChannelsToNotify.length === 0 && (
                <>
                  You don't have any active notifications. You can configure them <Link href="/notifications">here</Link>.
                </>
              )}
              { (emailsToNotify.length > 0 || slackChannelsToNotify.length > 0) && (
                <> When a service you have enabled for experiences an outage, or changes their status in any way, we'll send notifications to:</>
              )}
              { emailsToNotify.length > 0 && (
                <>
                  <br /><br /><Typography variant='p' sx={{ fontWeight: 500}}>Email</Typography>
                  <ul>
                    { emailsToNotify.map(email => {
                      return <li key={email.email}>{ email.email }</li>
                    })}
                  </ul>
                </>
              )}
              { slackChannelsToNotify.length > 0 && (
                <>
                  <Typography variant='p' sx={{ fontWeight: 500}}>Slack</Typography>
                  <ul>
                    { slackChannelsToNotify.map(channel => {
                      return <li key={channel}>{ channel }</li>
                    })}
                  </ul>
                </>
              )}
              <Link href="/notifications">Configure more options.</Link>
            </Alert>
          )
        }
        {
          isUnlimited && isTeam && (
            <Alert severity='success'>
              <AlertTitle>You have unlimited services for your team! <Link target="_blank" href={portalUrl}>Click here to manage your account</Link></AlertTitle>
              You can manage your team members <Link href="/team/members">here.</Link>
              { emailsToNotify.length === 0 && slackChannelsToNotify.length === 0 && (
                <>
                  You don't have any active notifications. You can configure them <Link href="/notifications">here</Link>.
                </>
              )}
              { (emailsToNotify.length > 0 || slackChannelsToNotify.length > 0) && (
                <> When a service you have enabled for experiences an outage, or changes their status in any way, we'll send notifications to:</>
              )}
              { emailsToNotify.length > 0 && (
                <>
                  <br /><br /><Typography variant='p' sx={{ fontWeight: 500}}>Email</Typography>
                  <ul>
                    { emailsToNotify.map(email => {
                      return <li key={email.email}>{ email.email }</li>
                    })}
                  </ul>
                </>
              )}
              { slackChannelsToNotify.length > 0 && (
                <>
                  <Typography variant='p' sx={{ fontWeight: 500}}>Slack</Typography>
                  <ul>
                    { slackChannelsToNotify.map(channel => {
                      return <li key={channel}>{ channel }</li>
                    })}
                  </ul>
                </>
              )}
              <Link href="/notifications">Configure more options.</Link>
            </Alert>
          )
        }
        <List sx={{ width: '100%', bgcolor: 'background.paper'}} subheader={<ListSubheader>Active Services</ListSubheader>}>
          { alerts.map(alert => {
            let notifier = availableNotifiers.find(elem => elem.name === alert.notifierName);
            if (notifier === undefined) {
              return null;
            }
            let active = activeNames.includes(notifier.name);
            console.log('active', active);
            return (
              <ListItem 
                key={notifier.name}
                secondaryAction={
                  <Button disabled={disableAlertButtons} onClick={() => enableAlert(notifier.name)} variant={active ? 'contained': 'outlined'}>{ active ? `Disable ${notifier.name}` : `Enable ${notifier.name}` }</Button>
                }
                disablePadding
              >
                {/* <ListItemButton> */}
                  <ListItemAvatar>
                    <Avatar 
                      alt={notifier.name}
                      src={notifier.logo}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={notifier.name} secondary={<Link href={notifier.statusPageLink}>Status Page Link</Link>} />
                {/* </ListItemButton> */}
              </ListItem>
            )
          })}
        </List>
        <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Search All Services"
            type="text"
            fullWidth
            variant="standard"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        <List sx={{ width: '100%', bgcolor: 'background.paper'}} subheader={<ListSubheader>All Services</ListSubheader>}>
          { searchedNotifiers.slice((currentPage - 1) * 10, ((currentPage - 1) * 10) + 10).map(notifier => {
            let active = activeNames.includes(notifier.name);
            return (
              <ListItem 
                key={notifier.name}
                secondaryAction={
                  <Button disabled={disableAlertButtons} onClick={() => enableAlert(notifier.name)} variant={active ? 'contained': 'outlined'}>{ active ? `Disable ${notifier.name}` : `Enable ${notifier.name}` }</Button>
                }
                disablePadding
              >
                {/* <ListItemButton> */}
                  <ListItemAvatar>
                    <Avatar 
                      alt={notifier.name}
                      src={notifier.logo}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={notifier.name} secondary={<Link href={notifier.statusPageLink}>Status Page Link</Link>} />
                {/* </ListItemButton> */}
              </ListItem>
            )
            })}
        </List>
        
        <Pagination sx={{ marginBottom: 2 }} count={Math.round(searchedNotifiers.length / 10)} page={currentPage} onChange={(e, val) => setCurrentPage(val)} />
      </div>
    )
  }

  return (
    <div>
      <Nav currentPage="Services" />
      <Container style={{ marginTop: 20 }}>
        <Typography style={{ marginBottom: 10 }} variant="h4">Services</Typography>
        { renderAlerts() }
      </Container>
    </div>
  )
}